import PropTypes from 'prop-types';
import { ElementLinkPropTypes } from '../../../ElementLink';

const heroPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  textJustification: PropTypes.oneOf(['left', 'center']).isRequired,
  textTheme: PropTypes.oneOf(['light', 'dark']).isRequired,
  height: PropTypes.oneOf(['Full', 'Medium', 'Short']),
  title: PropTypes.string.isRequired,
  bodyString: PropTypes.string,
  subtitle: PropTypes.string,
  scrollText: PropTypes.string,
  scrollTextTheme: PropTypes.string,
  backgroundColor: PropTypes.string,
  button: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes))
};

export default heroPropTypes;
