import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ErrorBoundary from '../../ErrorBoundary';
import ElementLink, { ElementLinkPropTypes } from '../../ElementLink';
import sidekickInit from '../../../utils/sidekick/init';

export const CtaButtonsPropTypes = {
  button: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)),
  contentOffset: PropTypes.oneOf(['Left', 'Right', 'None']),
  textJustification: PropTypes.oneOf(['left', 'center']).isRequired,
  ctaButtonsProps: PropTypes.shape({})
};

const useStyles = makeStyles(() => ({
  btnComboWrap: ({ button, contentOffset, textJustification }) => {
    /**
     * placeContent
     * @param {*} offset
     * @param {*} justification
     * @returns Bs flex class for centering content
     * NOTE: `contentOffset` overrides `textJustification`
     * -- meaning any offset will center align text, CTAs
     *
     * Diagram: content positioning
     * Legend: [ HERO ]
     * Content group: ***
     * Alignment: < or >
     * Text: *
     *
     * `contentOffset` position:
     * [ --- *** --- ] None
     * [ *** ------ ] Left
     * [ ------ *** ] Right
     *
     * `textJustification` position:
     * (^misnomer -- is actually alignment)
     * [ --- * --- ] center
     * [ * ------- ] left
     * [ ------- * ] right
     */
    const placeContent = (offset, justification) => {
      switch (true) {
        case ((!offset || offset === 'None') && (!justification || justification === 'left')) ||
          (offset === 'Left' && justification === 'left'):
          return {
            justifyContent: 'flex-start'
          };
        case justification === 'right':
          return {
            justifyContent: 'flex-end'
          };
        default:
          return {
            justifyContent: 'center'
          };
      }
    };

    return (
      button?.length > 1 && {
        display: 'flex',
        alignItems: 'center',
        ...placeContent(contentOffset, textJustification)
      }
    );
  }
}));

function CtaButtons({ button, contentOffset, textJustification }) {
  const props = { button, contentOffset, textJustification };
  const classes = useStyles(props);
  const buttons = button?.map((item, idx) => (
    <Box mt={3} mr={button.length > 1 && idx !== button.length - 1 ? 3 : undefined} key={item.internalTitle}>
      <ElementLink
        key={item.internalTitle}
        style={item.style}
        href={item.href}
        as={item.as}
        linkText={item.linkText}
        icon={item.icon}
        target={item.target}
        isModal={item.isModal}
        download={item.download}
        trackingId={item.trackingId}
        {...sidekickInit(item).sidekicker('CTA Button')}
      />
    </Box>
  ));

  return (
    <ErrorBoundary>
      {button && <div className={button?.length > 1 && classes.btnComboWrap}>{buttons}</div>}
    </ErrorBoundary>
  );
}

CtaButtons.propTypes = CtaButtonsPropTypes;
CtaButtons.defaultProps = {
  button: null,
  contentOffset: null,
  ctaButtonsProps: {}
};

export default CtaButtons;
