/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import ErrorBoundary from '../../ErrorBoundary';
import Image, { ImagePropTypes } from '../../Image';

export const BackgroundImagePropTypes = {
  'height': PropTypes.oneOf(['full', 'medium', 'short']).isRequired,
  'image': PropTypes.shape(ImagePropTypes).isRequired,
  'imageTablet': PropTypes.shape(ImagePropTypes),
  'imageMobile': PropTypes.shape(ImagePropTypes),
  'backgroundColor': PropTypes.string,
  'data-testid': PropTypes.string,
  'videoMobile': PropTypes.string,
  'videoDesktop': PropTypes.string
};

const protocol = (url) => {
  if (!/^((http|https):\/\/)/.test(url)) {
    return `https:${url}`;
  }
  return url;
};

const getMediaUrl = (media) => (media ? media.secure_url || `https:${media.url}` : '');

function HeroBackgroundImage({
  height,
  image,
  imageTablet,
  imageMobile,
  backgroundColor,
  videoMobile,
  videoDesktop,
  'data-testid': dataTestId
}) {
  const videoDesktopMedia = videoDesktop?.desktop || videoDesktop?.media?.[0];
  const videoMobileMedia = videoMobile?.mobile || videoMobile?.media?.[0];
  const videoDesktopUrl = getMediaUrl(videoDesktopMedia);
  const videoMobileUrl = getMediaUrl(videoMobileMedia);
  return (
    <ErrorBoundary>
      <BgWrap backgroundColor={backgroundColor} height={height}>
        {videoMobileUrl ? (
          <BgVideo
            className="video-mobile"
            muted
            loop
            autoPlay
            preload="auto"
            playsInline
            data-src={videoMobileUrl}
          />
        ) : null}

        {videoDesktopUrl ? (
          <BgVideo
            className="video-desktop"
            muted
            loop
            autoPlay
            preload="auto"
            playsInline
            data-src={videoDesktopUrl}
          />
        ) : null}
        <ArtDirectedBackground
          dataTestId={dataTestId}
          image={image}
          imageMobile={imageMobile}
          imageTablet={imageTablet}
          backgroundColor={backgroundColor}
          height={height}
        />
      </BgWrap>
    </ErrorBoundary>
  );
}

const ArtDirectedBackground = ({ dataTestId, image, imageMobile, imageTablet }) => {
  const backgrounds = [
    imageMobile?.url ? (
      <ResponsiveImage
        sizes="100vw"
        maxwidth={768}
        displaymedia="max-width: 767.9px"
        media="(max-width: 767.98px)"
        src={protocol(imageMobile?.url)}
        alt={image?.description || ''}
        priority
        testId={dataTestId || 'HeroBackgroundImage'}
        width={imageMobile.width}
        height={imageMobile.height}
      />
    ) : null,
    imageTablet?.url ? (
      <ResponsiveImage
        maxwidth={1024}
        sizes="100vw"
        displaymedia="max-width: 1023.98px"
        media="(min-width: 768px) and (max-width: 1023.98px)"
        src={protocol(imageTablet?.url)}
        alt={image?.description || ''}
        priority={!imageMobile?.url}
        testId={dataTestId || 'HeroBackgroundImage'}
        width={imageTablet.width}
        height={imageTablet.height}
      />
    ) : null,

    image ? (
      <ResponsiveImage
        sizes="100vw"
        src={protocol(image?.url)}
        media="(min-width: 1024px)"
        displaymedia={
          imageTablet?.url ? 'min-width: 1024px' : imageMobile?.url ? 'min-width:768px' : ''
        }
        alt={image?.description || ''}
        priority={!imageMobile?.url}
        testId={dataTestId || 'HeroBackgroundImage'}
        width={image?.width}
        height={image?.height}
      />
    ) : null
  ];
  return backgrounds;
};

const ResponsiveImage = styled(Image)`
  width: 100%;
  height: 100%;
  ${({ displaymedia }) =>
    displaymedia
      ? css`
          display: none !important;

          @media (${displaymedia}) {
            display: block !important;
          }
        `
      : ''}
`;

const bgTheme = css`
  background: ${({ theme }) => theme.background};
`;

const BgWrap = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  ${(props) =>
    props.height === 'full' &&
    css`
      position: sticky;
      top: 90px;
      height: calc(100vh - 90px);

      @media (max-width: 1024px) {
        top: 70px;
        height: calc(100vh - 70px);
      }
    `}

  ${(props) =>
    (props.height === 'medium' || props.height === 'short') &&
    css`
      height: 100%;
    `}

  @media (max-width: 767.98px) {
    > div {
      height: 100%;
    }
  }

  img {
    object-fit: cover;
  }

  ${({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor ?? theme.backgroundColor ?? 'transparent'};
    ${theme.background ? bgTheme : null}
  `}
`;

const BgVideo = styled.video`
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

HeroBackgroundImage.propTypes = BackgroundImagePropTypes;
HeroBackgroundImage.defaultProps = {
  'imageTablet': null,
  'imageMobile': null,
  'backgroundColor': 'transparent',

  'data-testid': undefined,
  'videoMobile': null,
  'videoDesktop': null
};

export default React.memo(HeroBackgroundImage);
