import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import useTranslation from 'next-translate/useTranslation';
import ErrorBoundary from '../../ErrorBoundary';
import muiTheme from '../../../theme/mui-theme';

export const HeroPropTypes = {
  scrollText: PropTypes.string.isRequired,
  scrollTextTheme: PropTypes.oneOf(['light', 'dark'])
};

const useStyles = makeStyles(() => ({
  'scrollTextWrap': ({ scrollTextTheme }) => {
    const scrollTextThemeCss =
      scrollTextTheme === 'light'
        ? {
            color: muiTheme.palette.white
          }
        : {
            color: muiTheme.palette.black
          };

    return {
      'position': 'absolute',
      'left': '50%',
      'bottom': '-4.5rem',
      'width': '100%',
      'height': '7.5rem',
      'fontFamily': muiTheme.typography.h1.fontFamily,
      'transform': 'translateX(-50%)',

      '@media (max-height: 650px)': {
        display: 'none'
      },

      '@media (max-height: 800px) and (max-width: 1440px)': {
        display: 'none'
      },

      [muiTheme.breakpoints.up('md')]: {
        bottom: '-3.75rem',
        width: '38rem'
      },

      '& span': {
        display: 'block',
        height: '2.1875rem',
        margin: '0 auto',
        fontSize: '1.9375rem',
        textAlign: 'center'
      },

      ...scrollTextThemeCss
    };
  },

  'scrollText': ({ lang }) => {
    return {
      display: 'block',
      paddingBottom: '0.75rem',
      textTransform: 'uppercase',

      ...(lang === 'cn' && {
        [muiTheme.breakpoints.down('sm')]: {
          fontSize: '1.6rem !important'
        }
      })
    };
  },

  'arrow': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    width: '2.03125rem',
    marginTop: '0.4375rem',
    transform: 'translate(-50%, -50%) rotate(90deg)',
    animation: '$bounce 0.5s',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite'
  },

  '@keyframes bounce': {
    from: {
      transform: 'translate3d(-50%, -50%, 0) rotate(90deg)'
    },
    to: {
      transform: 'translate3d(-50%, -100%, 0) rotate(90deg)'
    }
  },

  'waves': {
    'position': 'absolute',
    'top': '50%',
    'left': '50%',
    'display': 'block',
    'width': '3.1875rem',
    'marginTop': '2.1875rem',
    'transform': 'translate(-50%, -50%)',

    '& svg': {
      width: '100%'
    },

    '& path': {
      fill: muiTheme.palette.blue
    }
  },

  'small': {
    height: '2.1875rem',
    marginTop: '1.9375rem'
  }
}));

function ScrollText({ scrollText, scrollTextTheme, ...scrollTextProps }) {
  const { lang } = useTranslation();
  const props = { scrollText, scrollTextTheme, lang };
  const classes = useStyles(props);

  return (
    <ErrorBoundary>
      <div data-testid="ScrollText" className={cx('h4', classes.scrollTextWrap, 'scroll-more')}>
        <span
          data-testid="ScrollText-scrollText"
          className={classes.scrollText}
          {...scrollTextProps}
        >
          {scrollText}
        </span>
        <span role="none">
          <svg
            focusable="false"
            aria-hidden="true"
            className={classes.arrow}
            id="arrow-long"
            imageRendering="auto"
            baseProfile="basic"
            version="1.1"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 62 35"
          >
            <rect x="0" y="14.7" width="55.2" height="4.55" />
            <g transform="translate(40.9 0)">
              <path
                stroke="none"
                d="M.35 3L14.3 17.2 0 31.25 3.35 34.25 20.85 17.15 3.5 0 .35 3Z"
              />
            </g>
          </svg>
        </span>
        <span className={classes.small} role="none">
          <div className={classes.waves}>
            <svg
              focusable="false"
              id="waves"
              imageRendering="auto"
              baseProfile="basic"
              version="1.1"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 102 19"
              aria-hidden="true"
            >
              <g id="Waves" transform="translate(0 .05)">
                <animateTransform
                  attributeName="transform"
                  additive="replace"
                  type="translate"
                  repeatCount="indefinite"
                  dur="0.792s"
                  keyTimes="0;.05299;.053;.10499;.105;.15799;.158;.20999;.21;.26299;.263;.31599;.316;.36799;.368;.42099;.421;.47299;.473;.52599;.526;.57899;.579;.63099;.631;.68399;.684;.73699;.737;.78899;.789;.84199;.842;.89399;.894;.94699;.947;1"
                  values="0,.05;0,.05;-2.5,.05;-2.5,.05;-5,.05;-5,.05;-7.5,.05;-7.5,.05;-10,.05;-10,.05;-12.5,.05;-12.5,.05;-15,.05;-15,.05;-17.5,.05;-17.5,.05;-20,.05;-20,.05;-22.5,.05;-22.5,.05;-25,.05;-25,.05;-27.5,.05;-27.5,.05;-30,.05;-30,.05;-32.5,.05;-32.5,.05;-35,.05;-35,.05;-37.5,.05;-37.5,.05;-40,.05;-40,.05;-42.5,.05;-42.5,.05;-45,.05;-45,.05"
                  calcMode="discrete"
                />
                <path
                  fill="#000"
                  stroke="none"
                  d="M146.35 18.95L146.5 18.95Q149.205 18.95 151.6 18.2L151.6 11.2Q149.306 12.65 146.45 12.65L146.35 12.65Q143.4 12.65 141.1 11.15 141.05 11.1 141 11.1 139.55 10.15 136.9 7.35 133.65 3.9 131.35 2.45 127.45 0 122.55 0L122.5 0Q117.9 0 114.2 2.15 114.15 2.2 114.1 2.25 114 2.25 113.95 2.3 113.9 2.35 113.85 2.4 113.75 2.4 113.7 2.45 111.836 3.624 109.35 6.1 108.767 6.694 108.15 7.35 105.5 10.15 104.05 11.1 101.7 12.65 98.75 12.65L98.65 12.65Q96.8 12.65 95.25 12.1L94.3 11.7Q93.85 11.45 93.4 11.15 93.35 11.1 93.3 11.1 91.85 10.15 89.2 7.35 85.95 3.9 83.65 2.45 79.75 0 74.85 0L74.8 0Q70.2 0 66.5 2.15 66.4 2.2 66.3 2.3L66.25 2.3Q66.15 2.35 66.05 2.45L66 2.45Q63.7 3.9 60.45 7.35 57.8 10.15 56.35 11.1 54 12.65 51 12.65L50.9 12.65Q48.143 12.65 45.95 11.35L45.75 11.2Q45.716 11.178 45.65 11.15 45.641 11.128 45.6 11.1 45.267 10.881 44.85 10.55L44.6 10.35Q43.355 9.307 41.55 7.4 41.524 7.375 41.5 7.35L36.9 3.1Q36.404 2.736 35.95 2.45 32.05 0 27.15 0 22.2 0 18.3 2.45 16 3.9 12.75 7.35 10.1 10.15 8.65 11.1 6.3 12.65 3.3 12.65 3.011 12.65 2.75 12.7 .053 12.567 -2.05 11.15 -2.5 10.85 -3.05 10.4L-3.05 17.8Q-.15 18.95 3.2 19L3.35 19Q8.25 19 12.15 16.55 14.55 15.1 17.7 11.65 20.35 8.85 21.8 7.9 24.15 6.35 27.15 6.35L27.2 6.35Q30.1 6.35 32.4 7.8 32.45 7.85 32.55 7.9 32.909 8.135 33.35 8.45L36.6 11.6Q36.624 11.625 36.65 11.65L41.25 15.9Q41.746 16.263 42.2 16.55 46.05 18.95 50.9 19 50.95 19 51 19L51.15 19Q56 19 59.85 16.55 60.7 16 61.7 15.2L61.7 15.1Q63.378 13.759 65.35 11.6 68 8.8 69.45 7.85 71.8 6.3 74.8 6.3L74.85 6.3Q77.75 6.3 80.05 7.75 80.1 7.8 80.2 7.85 81.65 8.8 84.3 11.6 87.55 15.05 89.85 16.5 93.7 18.9 98.55 18.95 98.6 18.95 98.65 18.95L98.8 18.95Q103.65 18.95 107.5 16.5 108.358 15.958 109.35 15.15 111.013 13.761 113.05 11.6 115.7 8.8 117.15 7.85 119.5 6.3 122.5 6.3L122.55 6.3Q125.45 6.3 127.75 7.75 127.85 7.8 127.9 7.85 129.35 8.8 132 11.6 135.25 15.05 137.55 16.5 141.45 18.95 146.35 18.95Z"
                />
              </g>
            </svg>
          </div>
        </span>
      </div>
    </ErrorBoundary>
  );
}

ScrollText.propTypes = HeroPropTypes;
ScrollText.defaultProps = {
  scrollTextTheme: null
};

export default ScrollText;
